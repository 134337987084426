
import React from 'react';
import BaseRoutes from './routes/BaseRoutes';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    < >

      <BrowserRouter>
        <BaseRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
