import React ,{Suspense, useState, useEffect} from 'react';
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch
  } from "react-router-dom";

import Loader from '../components/Loader';



// page imports 
  const Webinar = React.lazy(() => import("../pages/WebinarPage/Webinar"));
  const Home = React.lazy(() => import("../pages/Home/Home"));
 
// page imports 
function BaseRoutes() {

    return (
        <div className="h-full flex flex-col">
            <Suspense fallback={Loader}>

            {/* Middle content */}
            <div className="flex-grow">
                <Switch>
                    
                    <Route path="/" exact>
                        <Home /> 
                    </Route>  
                    <Route path="/september9" exact>
                        {/* <Home />  */}
                        <Webinar />
                    </Route>  

                    <Redirect from="*" to="/"/>
                </Switch>
            </div>

            </Suspense>
        </div>
    )
}

export default BaseRoutes
